import { createApp } from 'vue'

import App from './App.vue'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
// import App from './views/BLog/App.vue';
import router from './router'
import store from './store'

// import anime from '../node_modules/animejs/lib/anime.es.js';
// import Vue from 'vue';
// import VueCarousel from 'vue-carousel'
// Vue.use(VueCarousel);
createApp(App).use(store).use(router).mount('#app')
