import { createStore } from 'vuex'
import axios from '../../node_modules/axios'

export default createStore({
  state: {
    accommodation: {},
    nearby_attraction:{},
    BaseUrl : process.env.VUE_APP_BASE_URL
  },
  getters: {
    // this.get_accommodation();
  },
  mutations: {
  },
  actions: {
    async get_accommodation() {
      try {
         const response = await axios.get(`${this.state.BaseUrl}/accommodation`);
         this.state.accommodation = response.data.data;
         console.log('Vuex=>',this.state.accommodation);
      } catch (error) {
         console.error(error);
      }
    },
    async get_nearby_attraction() {
      try {
         const response = await axios.get(`${this.state.BaseUrl}/nearby_attraction`);
         this.state.nearby_attraction = response.data.data
         console.log(this.state.nearby_attraction);
      } catch (error) {
         console.error(error);
      }
      }
  },
  modules: {
  }
})
