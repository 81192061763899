import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: ()=>import('../views/home/HomeView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: ()=>import('../views/home/HomePage.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact/Contact.vue')
  },
  {
    path:'/accommodation',
    name:'accommodation',
    component:() => import('../views/accommodation/Accommodation.vue')
  },
  {
    path:'/accommodation/room/:id',
    name:'accommodation/room',
    component:() => import('../views/accommodation/AccommodationDetail.vue')
  },
  {
    path:'/bar_restaurant',
    name:'bar_resturant',
    component:() => import('../views/bar_restaurant/Bar_Restaurant.vue')
  },
  {
    path:'/:name',
    name:'detail',
    component:() => import('../views/bar_restaurant/Detail.vue')
  },
  {
    path:'/facilities',
    name:'facilities',
    component:() => import('../views/facilities/Facilities.vue')
  },
  {
    path:'/health_fitness',
    name:'health_fitness',
    component:() => import('../views/health_fitness/Health_Fitness.vue')
  },
  {
    path:'/shop_with_me',
    name:'shop_with_me',
    component:() => import('../views/shop_with_me/Shop_with_me.vue')
  },
  {
    path:'/nearby',
    name:'nearby',
    component:() => import('../views/nearby/Nearby.vue')
  },
  {
    path:'/booking',
    name:'booking',
    component:() => import('../views/booking/Booking.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 200)
    })
  },
})

export default router
